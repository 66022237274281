<template>
  <navbar />
  <main class="bg-color">
    <div
      id="home"
      class="container-fluid p-0 banner-container text-center"
    >
      <img
        src="../../public/web_banner.png"
        class="img-fluid"
        alt="slot-banner"
      >
      <div class="banner-text-container">
        <h1 class="text-white banner-title">
          Next level gaming
        </h1>
        <p class="text-white banner-text">
          We deliver high-end, next level products <br>to the gaming market
        </p>
      </div>
    </div>
    <div id="about">
      <h1 class="mt-5 text-white text-center">
        About Deltastake
        <div class="mx-auto red-line" />
      </h1>
      <div class="container-fluid row justify-content-center">
        <img
          src="../../public/betting.png"
          class="betting-image align-middle"
        >
        <div class="about-text-container">
          <p class=" text-white lead">
            Deltastake's main focus is the development of high quality HTML5 slots and other online
            casino
            games. Our team of experts have over 12 years of experience in the industry to create games
            to the highest standards using proven formulas for success combined with the latest developments
            in
            the gaming industry. We use the latest technologies in the IT industry - blockchain, Dapp, 3D
            and more.
            In total, our team has created more than 120 projects. We develop projects, both under the
            order and in the form of our products.
          </p>
          <p class=" text-white lead">
            Our vision is to be a company that is a reliable partner for its most demanding customers
            around
            the
            world. We want to create games for our partners that will be of interest to the widest
            possible
            audience. We pay special attention to the quality of products and services. Our full-cycle
            studio,
            we create projects ourselves, from idea to full implementation.
            Contact us and we will tell you more about our possibilities.
          </p>
        </div>
      </div>
    </div>
    <div
      id="priority"
      class="container-fluid"
    >
      <h1 class="mt-5 text-white text-center">
        Our priority
        <div class="mx-auto red-line" />
      </h1>
      <div class="mx-auto col-11 col-sm-10 col-md-8 col-lg-10">
        <Carousel
          class="text-center rounded"
          :per-page-custom="[[320, 1], [577, 2], [992, 3]]"
          :autoplay="true"
          :auto-play-hover-pause="true"
          :mouse-drag="false"
          :loop="true"
        >
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/implementation.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                Full Cycle development
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                From concept to completion, our games are fully developed by our team. We have all the
                necessary specialists for the development and support of projects.
              </p>
            </div>
          </Slide>
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/new-product.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                Custom development
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                Our studio also outsources orders for partners. Therefore, you can cooperate with us on
                this issue as well. Email contact@deltastake.com
              </p>
            </div>
          </Slide>
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/gambling.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                web gambling games
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                The main direction of the studio is the release of optimized toys for browsers, both in
                2D and 3D format.
              </p>
            </div>
          </Slide>
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/free-icon-software-1163514.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                Crypto gambling games
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                Our studio has experience in developing gambling games with blockchain implementation
                (partial and full Dapp functionality).
              </p>
            </div>
          </Slide>
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/innovation.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                Innovative features
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                We are constantly developing and adding new progressive technologies for a more
                interesting gaming experience, as well as ensuring a level of security for our users.
              </p>
            </div>
          </Slide>
          <Slide class="text-white">
            <div class="slide">
              <img
                src="../../public/design-thinking.png"
                class="priority-image"
              >
              <h4 class="text-uppercase mt-5">
                Creative content
              </h4>
              <div class="mx-auto red-line" />
              <p class="lead mt-3">
                Our games are based on memorable designs and exciting storylines, as well as various
                levels of volatility and the possibility of receiving a large number of bonuses.
              </p>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
    <div
      id="experience"
      class="container-fluid"
    >
      <h1 class="mt-5 text-white text-center">
        Our experience
        <div class="mx-auto red-line" />
      </h1>
      <div class="experience-container col-12 text-center position-relative">
        <div
          class="experience-year-block col-11 col-sm-6 col-md-6 col-lg-8 col-xl-8  col-xxl-7 d-flex align-items-center mx-4"
          style="background-color: #181818;"
        >
          <img
            src="../../public/banner2.png"
            class="experience-banner-img"
            alt="slot-banner"
          >
          <div class="ms-3">
            <h1 class="h1 dark-red experience-title">
              12+
            </h1>
            <h4 class="text-grey experience-subtitle">
              Years experience
            </h4>
          </div>
        </div>
        <div class="experience-blocks col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
          <div class="col-12 col-sm-6 col-md-6 col-lg-12 d-flex">
            <div
              class="experience-blocks-block col-6 col-sm-4 col-md-2 col-lg-1 col-xl-1 col-xxl-2 me-2 d-flex flex-column justify-content-center py-3 px-5 mt-3"
            >
              <h3 class="dark-red experience-title">
                60+
              </h3>
              <h5 class="text-grey experience-subtitle">
                clients
              </h5>
            </div>
            <div
              class="experience-blocks-block col-6 col-sm-4 col-md-2 col-lg-1 col-xl-1 col-xxl-2 d-flex flex-column justify-content-center py-3 px-5 mt-3"
            >
              <h3 class="dark-red experience-title">
                122+
              </h3>
              <h5 class="text-grey experience-subtitle">
                gambling games
              </h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-12 d-flex">
            <div
              class="experience-blocks-block col-6 col-sm-4 col-md-2 col-lg-1 col-xl-1 col-xxl-2 me-2 d-flex flex-column justify-content-center py-3 px-5 mb-3 mt-2 "
            >
              <h3 class="dark-red experience-title">
                10+
              </h3>
              <h5 class="text-grey experience-subtitle">
                gambling games engines
              </h5>
            </div>
            <div
              class="experience-blocks-block col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1 col-xxl-2 d-flex flex-column justify-content-center py-3 px-5 mb-3 mt-2"
            >
              <h3 class="dark-red experience-title">
                400+
              </h3>
              <h5 class="text-grey experience-subtitle">
                games use these engines
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="projects"
      class="container-fluid"
    >
      <h1 class="text-white text-center fw-normal">
        Featured Projects
        <div class="mx-auto red-line" />
      </h1>
      <div>
        <Carousel
          class="projects-carousel text-center col-12 col-sm-12 col-md-11 col-lg-10 mx-auto"
          :per-page-custom="[[320, 1], [577, 2], [992, 3]]"
          :autoplay="true"
          :auto-play-hover-pause="true"
          :mouse-drag="false"
          :loop="true"
          :autoplay-timeout="10000"
        >
          <Slide>
            <div class="slide project-slide col-10 col-sm-10 col-md-10 col-lg-11">
              <img
                src="../../public/2.jpg"
                class="projects-image"
              >
              <div class="hidden-content">
                <h3 class="text-uppercase text-white project-title">
                  Muta fruit
                </h3>
                <p class="text-white project-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
                  natus
                  pariatur animi! Fugit
                  molestias, eligendi inventore earum enim perspiciatis beatae neque doloribus illo repudiandae facere
                  consequatur minus consequuntur deleniti iusto!
                </p>
              </div>
            </div>
          </Slide>
          <Slide>
            <div class="slide project-slide col-10 col-sm-10 col-md-10 col-lg-11">
              <img
                src="../../public/3.jpg"
                class="projects-image"
              >
              <div class="hidden-content">
                <h3 class="text-uppercase text-white project-title">
                  Muta fruit
                </h3>
                <p class="text-white project-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
                  natus
                  pariatur animi! Fugit
                  molestias, eligendi inventore earum enim perspiciatis beatae neque doloribus illo repudiandae facere
                  consequatur minus consequuntur deleniti iusto!
                </p>
              </div>
            </div>
          </Slide>
          <Slide>
            <div class="slide project-slide col-10 col-sm-10 col-md-10 col-lg-11">
              <img
                src="../../public/4.jpg"
                class="projects-image"
              >
              <div class="hidden-content">
                <h3 class="text-uppercase text-white project-title">
                  Muta fruit
                </h3>
                <p class="text-white project-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
                  natus
                  pariatur animi! Fugit
                  molestias, eligendi inventore earum enim perspiciatis beatae neque doloribus illo repudiandae facere
                  consequatur minus consequuntur deleniti iusto!
                </p>
              </div>
            </div>
          </Slide>
          <Slide>
            <div class="slide project-slide col-10 col-sm-10 col-md-10 col-lg-11">
              <img
                src="../../public/5.jpg"
                class="projects-image"
              >
              <div class="hidden-content">
                <h3 class="text-uppercase text-white project-title">
                  Muta fruit
                </h3>
                <p class="text-white project-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
                  natus
                  pariatur animi! Fugit
                  molestias, eligendi inventore earum enim perspiciatis beatae neque doloribus illo repudiandae facere
                  consequatur minus consequuntur deleniti iusto!
                </p>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
    <div
      id="contact"
      class=" mx-auto col-11 col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-6 mt-5"
    >
      <div class="contact-container">
        <img
          src="../../public/email.png"
          class="email-image"
          alt="email"
        >
        <h3 class=" contact-title text-white text-uppercase fw-normal">
          Interested? Get
          in
          touch!
        </h3>
        <p class="contact-text text-white">
          If you would like to contact us, please send us an email: <a
            href="mailto: contact@deltastake.com"
            style="text-decoration: none"
            class="dark-red"
          >contact@deltastake.com</a>
        </p>
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';
import { Carousel, Slide } from '@jambonn/vue-concise-carousel';
import '@jambonn/vue-concise-carousel/lib/vue-concise-carousel.css'

export default {

  components: {
    Navbar,
    Footer,
    Carousel,
    Slide
  }
  ,
}
</script>

<style scoped>
.banner-container {
  position: relative;
  background-color: #080808;
  margin-top: 4rem;
}

.banner-text-container {
  display: inline-block;
  position: absolute;
  left: 48%;
  top: 30%;
  font-size: 1rem;
}

.banner-text {
  font-size: 1.16rem;
}

.betting-image,
.priority-image,
.email-image {
  display: inline-block;
  filter: opacity(0.5) drop-shadow(0 0 0 #bababa);
  margin: 0 1.5rem 0 4rem;
  max-width: 14rem;
  max-height: 14rem;
}

.betting-image {
  max-width: 18rem;
  max-height: 18rem;
  width: 100%;
  height: 100%;
  display: none;
}

.about-text-container {
  width: 90%;
}

.slide {
  margin-left: 1rem;
}


.priority-image {
  margin: 0;
}

.h1 {
  font-size: 4rem;
  font-weight: bolder;
}

.experience-banner-img {
  visibility: hidden;
  width: 60%;
  height: 100%;
}

.experience-blocks {
  position: absolute;
  left: 55%;
  top: 12%;
}

.experience-title {
    margin-bottom: 0;
  }

  .experience-subtitle {
    margin-bottom: 0;
  }

#projects {
  margin-top: 5rem;
}

.project-slide {
  position: relative;
}

.hidden-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  scale: 1.01;
  transform: translateY(100%);
  background-color: #1e1e1e;
  transition: transform 200ms linear;
}

.project-title {
  margin-top: 1rem;
  font-size: calc(.1rem + 1rem);
  padding: 0 .5rem;
}

.project-text {
  margin: 0 !important;
  text-align: start !important;
  padding: 0 1rem;
}

.project-slide:hover .hidden-content {
  transform: translateY(0);
}

.projects-image {
  width: 100%;
}

#contact {
  background-color: #1e1e1e;
  padding: 2rem 0;
}

.contact-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.email-image {
  margin: auto 1rem auto 2rem;
  width: 60%;
  max-width: 20rem;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.contact-title {
  font-size: calc(1rem + 1rem);
  margin-top: 1rem;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;

}

.contact-text {
  font-size: calc(.1vw + 1.3rem);
  margin-top: 4rem;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
}

@media screen and (max-width: 576px) {

  .banner-title {
    font-size: 1.5rem;
  }

  .banner-text {
    font-size: .7rem;
  }

  .experience-year-block {
    padding: 2rem 0;
    margin: 0 auto !important;
    justify-content: center !important;
    ;
  }

  .experience-year-block>div {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .experience-year-block > div > h1 {
    margin-right: .5rem;
  }

  .experience-banner-img {
    display: none;
  }

  .experience-blocks {
    position: static;
    padding: 0 1.2rem;
  }

  .experience-blocks-block {
    width: 49%;
    padding: 1rem 0 !important;
    background-color: #1e1e1e;
  }

  .experience-title {
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  .experience-subtitle {
    font-size: 1.3rem;
    margin-bottom: 0;
  }

  #projects {
    margin-top: 1rem;
  }

  .project-slide {
    margin: 0 auto;
  }

  .projects-image {
    margin: 0 !important;
  }

  #contact {
    background-color: #080808;
    padding: 2rem 0;
  }

  .email-image {
    margin-top: 5rem;
  }

  .contact-title {
    font-size: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .contact-text {
    margin-top: 6rem;
    text-align: start;
    font-size: 1rem;
    grid-column-start: 2;
    grid-row-start: 1;
  }

}

@media screen and (min-width: 576px) {
  .project-text {
    font-size: calc(.1vw + .6rem);
  }

  .contact-title {
    font-size: calc(1rem + .5rem);
  }
}

@media screen and (min-width: 786px) {
  .experience-blocks-block {
    width: 50%;
    max-width: 20rem;
    background-color: #1e1e1e;
  }

  .project-text {
    font-size: calc(.1vw + .79rem);
  }

  .contact-title {
    font-size: calc(1rem + 1rem);
  }

}

@media screen and (min-width: 992px) {
  .betting-image {
    display: block;
  }

  .about-text-container {
    width: 53%;
  }

  .experience-container {
    padding-top: .3rem;
    padding-left: 5rem;
  }


  .experience-banner-img {
    visibility: visible;
  }

  .project-text {
    font-size: 1rem;
  }

}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .experience-year-block {
    padding: 6rem;
    justify-content: center !important;
  }

  .experience-year-block>div {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .experience-year-block h1 {
    font-size: 3rem;
  }

  .experience-year-block h4 {
    font-size: 1.5rem;
  }

  .experience-banner-img {
    display: none;
  }

  .experience-blocks {
    padding: 0 1.2rem;
    left: 40%;
    top: 10%;
  }

  .experience-blocks>div {
    margin: 0 !important;
  }

  .experience-blocks-block {
    width: 100%;
    padding: 1rem 0 !important;
    background-color: #1e1e1e;
  }

  .experience-title {
    font-size: 1.5rem;
  }

  .experience-subtitle {
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1509px) {

  .experience-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .experience-blocks {
    left: 63%;
  }

  .experience-blocks-block {
    padding: .5rem !important;
  }
}
</style>
<template>
  <footer class="footer row flex-nowrap mt-5 me-0 justify-content-center">
    <p class="copyright-text col-11 col-sm-6 col-md-8 col-lg-5 mb-0">
      ©2023 Deltastake is a game development studio
      producing slot machines and casino apps.
      Deltastake owns the
      intellectual property in the form of logos and graphics and they may not be copied, transmitted, distributed
      or displayed without writing in Deltastake west. All rights reserved.
    </p>
    <div class="col-6 col-sm-4 col-md-2 col-lg-2 d-flex align-items-center">
      <i
        class="fa-solid fa-dice-d20 dark-red brand-icon"
        @click="scroll('home')"
      />
      <span
        class="fs-4 text-white navbar-brand"
        style="margin-left: 20px"
      >Deltastake</span>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>

.footer {
    background-color: #000;
    padding: 20px 0;
}
.copyright-text {
    color: #585858;
    font-size: small;
}

@media screen and (max-width:576px) {
    .footer {
        flex-direction: column-reverse;
        align-items: center;
    }
}
</style>
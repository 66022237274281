
<template>
  <header class="header navbar navbar-expand-lg bg-color fixed-top">
    <div class="container-fluid justify-content-space-between">
      <div>
        <i
          type="button"
          class="fa-solid fa-dice-d20 dark-red navbar-brand brand-icon"
          @click="scroll('home')"
        />
        <span class="fs-4 text-white navbar-brand nav-bar-name">Deltastake</span>
      </div>
      <button
        class="navbar-toggler navbar-dark"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <nav
        id="navbarSupportedContent"
        class="collapse navbar-collapse nav_bar"
      >
        <ul class="navbar-nav mb-2 mb-lg-0 align-items-end">
          <li class="nav-item">
            <span
              type="button"
              class="nav-link text-white text-uppercase fw-semibold"
              @click="scroll('about')"
            >About</span>
          </li>
          <li class="nav-item">
            <span
              type="button"
              class="nav-link text-white text-uppercase fw-semibold"
              @click="scroll('priority')"
            >Priority</span>
          </li>
          <li class="nav-item">
            <span
              type="button"
              class="nav-link text-white text-uppercase fw-semibold"
              @click="scroll('experience')"
            >Experience</span>
          </li>
          <li class="nav-item">
            <span
              type="button"
              class="nav-link text-white text-uppercase fw-semibold"
              @click="scroll('projects')"
            >projects</span>
          </li>
          <li class="nav-item ">
            <span
              type="button"
              class="nav-link text-white text-uppercase fw-semibold"
              @click="scroll('contact')"
            >Contact</span>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
  }

}
</script>
	

<style scoped>
.nav_bar {
  flex-grow: 0;
}

.header {
  padding: 1rem;
}

.nav-link:hover {
  color: #A7121D!important;
}

@media screen and (max-width: 576px) {
  .nav-bar-name {
    margin-left: 4rem;

  }



  @media (min-width: 576px) {}
}
</style>
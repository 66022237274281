import { createApp, configureCompat } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './index.css'

configureCompat({ MODE: 2, COMPONENT_ASYNC: false, WATCH_ARRAY: false, RENDER_FUNCTION: false })

const app = createApp(App)

app.use(router).use(store).mount('#app')
